













































































import ClientFilters from "@/components/infoFilters/clientFilter.vue";
import InvoiceFilter from "@/components/infoFilters/invoiceFilter.vue";
import QuoteFilters from "@/components/infoFilters/quoteFilter.vue";
import templateFilters from "@/components/infoFilters/templateFilter.vue";
import PurchaseOrderFilters from "@/components/infoFilters/purchaseOrderFilter.vue";
import WorkOrderFilters from "@/components/infoFilters/workOrderFilter.vue";
import SupplierFilters from "@/components/infoFilters/supplierFilter.vue";
// import ClientSubDetails from "@/components/infoFilters/clientSubDetails.vue";
export default {
  data() {
    return {};
  },
  methods: {},
  components: {
    ClientFilters,
    QuoteFilters,
    templateFilters,
    PurchaseOrderFilters,
    WorkOrderFilters,
    SupplierFilters,
    // ClientSubDetails,
    InvoiceFilter
  }
};
